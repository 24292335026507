import { useEffect, useState } from "react";
import { useConfigSlice } from "~/old-app/redux/config-slice";
import { ApiOptions } from "~/old-app/types/api";
import { Product, ProductsQueryParams } from "../product";
import { useLazyServiceSearchQuery, useProductsQuery } from "./search-api";

export const useSearchProducts = (
  { page: currentPage, ...props }: ProductsQueryParams = {},
  options?: ApiOptions
) => {
  const { selectedService } = useConfigSlice();
  const [page, setPage] = useState(currentPage || 1);
  const [products, setProducts] = useState<Product[]>([]);

  // const { data, isLoading, isFetching,  } =
  //   useServiceSearchQuery(
  //     {
  //       ...props,
  //       page: currentPage || 1,
  //       service_id: selectedService,
  //     },
  //     { skip: options?.skip ? options?.skip : !selectedService }
  //   );

  const [getProducts, { data, isLoading, isFetching }] =
    useLazyServiceSearchQuery();

  const total = data?.response.meta?.total || 0;
  const hasMore = total !== products.length;

  const getProductsHandler = async (page: number, concat = true) => {
    try {
      const response = await getProducts({
        ...props,
        page,
        service_id: selectedService,
      }).unwrap();

      setProducts((prev) =>
        concat
          ? [...prev, ...response?.response?.data]
          : response?.response?.data || []
      );
    } catch (error) {}
  };

  useEffect(() => {
    if (isFetching) return;

    getProductsHandler(currentPage || 1, currentPage !== 1);
  }, [currentPage]);

  useEffect(() => {
    setProducts([]);

    getProductsHandler(1, false);
  }, [
    props?.category_id,
    props?.service_id,
    props?.text,
    props?.limit,
    props?.brands,
    props?.branch_id,
    props?.order_by,
    props?.rating,
    props?.min_price,
    props?.max_price,
    props?.category_names,
    props?.brand_names,
    props?.branch_names,
    props?.tag_ids,
  ]);

  return {
    products,
    total: data?.response.meta?.total || 0,
    isLoading,
    isFetching,
    setPage,
    hasMore,
    page,
    meta: data?.response.meta,
  };
};
export const useProductsWithoutConcat = (
  props?: ProductsQueryParams,
  options?: ApiOptions
) => {
  const { selectedService } = useConfigSlice();
  const [page, setPage] = useState(props?.page || 1);

  const { data, isLoading, isFetching } = useProductsQuery(
    {
      ...props,
      service_id: selectedService,
      page,
    },
    { skip: options?.skip || !selectedService }
  );

  const products = data?.response.data || [];
  const total = data?.response.meta?.total || 0;
  const hasMore = total !== products.length;

  return {
    products: data?.response.data || [],
    total: data?.response.meta?.total || 0,
    isLoading,
    isFetching,
    setPage,
    hasMore,
    page,
    meta: data?.response.meta,
  };
};
