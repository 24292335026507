// import { omitBy } from "lodash";
import { Response } from "../../types/api";
import { Api } from "../api-store";
import { Product, ProductsQueryParams } from "../product";
import { SearchProps } from "./search-types";

export const SearchApi = Api.injectEndpoints({
  endpoints: (builder) => ({
    serviceSearch: builder.query<Response<Product[]>, ProductsQueryParams>({
      providesTags: (result, error, params) => [
        { type: "products-search", ...params },
      ],
      query: (params) => ({
        url: `/services/search`,
        method: "GET",
        params,
        cache: "no-cache",
      }),

      // serializeQueryArgs: ({ endpointName, queryArgs }) => {
      //   const { page, ...queryParams } = queryArgs as any;

      //   // const params = new URLSearchParams();
      //   // Object.keys(queryParams).forEach((key) => {
      //   //   params.append(
      //   //     key,
      //   //     queryParams[key as keyof ProductsQueryParams]?.toString() || ""
      //   //   );
      //   // });

      //   // const queryParamsString = params.toString();

      //   return {
      //     endpointName,
      //     ...queryParams,
      //   };
      //   // return `${endpointName}?${queryParamsString}`;
      // },
      // merge: (
      //   currentCache: Response<Product[]>,
      //   newItems: Response<Product[]>
      // ) => {
      //   currentCache.response.data.push(...newItems.response.data);
      //   return currentCache as Response<Product[]>;
      // },

      // forceRefetch({ currentArg = {}, previousArg = {} }) {
      //   return Object.keys(currentArg).some(
      //     (key) => (currentArg as any)[key] !== (previousArg as any)[key]
      //   );
      // },
    }),
    products: builder.query<Response<Product[]>, ProductsQueryParams>({
      providesTags: (result, error, props) => [
        { type: "products-search", ...props },
      ],
      query: (params) => ({
        url: `/services/search`,
        method: "GET",
        params,
      }),
      keepUnusedDataFor: 0,
      async onCacheEntryAdded(arg, { cacheDataLoaded, cacheEntryRemoved }) {
        // Clear the cache when the entry is added
        await cacheDataLoaded;
        await cacheEntryRemoved;
      },
    }),
    // TODO: its not exist in post man, handle this later :(
    serviceBranchSearch: builder.query<Response<Product[]>, SearchProps>({
      query: (params) => ({
        url: `/services/branch/search`,
        method: "GET",
        params,
      }),
    }),
  }),
});

export const {
  useServiceSearchQuery,
  useLazyServiceSearchQuery,
  useProductsQuery,
} = SearchApi;
